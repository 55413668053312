import React from "react"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { colors } from "../theme"
import { Section, Title, Para } from "../components/basic"
import { Flex, Box } from "rebass"
import Img from "gatsby-image"

const Card = styled("div")`
  color: ${colors.black};
  background-color: ${colors.white};
  transition: all 0.3s ease-in-out;
  margin-bottom: 2rem;
  padding: 2rem;
`
const Image = styled("img")`
  top: 5%;
  right: -20%;
  position: absolute;
  max-height: 100%;
  padding: 20px;
`

export default ({ data }) => (
  <Layout hideHeader>
    <SEO
      title="Case Studies | ANMC"
      description="Over the years, we have helped 100s of businesses and individuals in
        achieving their business potential. Here, you can find some of the cases
        we worked on, and their results."
    />
    <Flex
      flexWrap="wrap"
      alignItems="center"
      flexDirection={["column", "row", "row", "row"]}
      my={[0, 8, 8, 9]}
      style={{ maxWidth: "1450px" }}
    >
      <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} px={[5, 5, 7, 8]} py={[6, 6, 0, 0]}>
        <Title>Case Studies</Title>
        <Para>
          Over the years, we have helped 100s of businesses and individuals in
          achieving their business potential. Here, you can find some of the
          cases we worked on, and their results.
        </Para>
        <Para>
          We are passionate for success and success is what we enable for anyone
          who comes to us.
        </Para>
      </Box>
      <Box order={[-1, 0, 0, 0]} width={[1, 1 / 2, 1 / 2, 1 / 2]}>
        <Img
          style={{ maxHeight: "450px" }}
          fluid={data.whatwedoImage.childImageSharp.fluid}
          imgStyle={{ objectPosition: "bottom center" }}
        />
      </Box>
    </Flex>
    <Section alternateBg style={{ position: "relative", overflow: "hidden" }}>
      <Image src="/img/Group6.svg" />
      {data.allMarkdownRemark.edges.map(casestudy => (
        <Link
          to={`/casestudy/${casestudy.node.frontmatter.title}`}
          key={casestudy.node.frontmatter.title}
          style={{ textDecoration: "none" }}
        >
          <Card>
            <Title>{casestudy.node.frontmatter.name}</Title>
            <Para
              dangerouslySetInnerHTML={{ __html: casestudy.node.html }}
              style={{
                overflow: "hidden",
                maxHeight: "200px",
              }}
            ></Para>
          </Card>
        </Link>
      ))}
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fields: { slug: { regex: "/casestudy/" } } }) {
      edges {
        node {
          frontmatter {
            title
            name
          }
          html
        }
      }
    }
    whatwedoImage: file(relativePath: { eq: "Whatwedo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
